import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';
import { ToggleAngle } from './table/ToggleAngle';
import Scroll from 'react-scroll';
import './AlfaFooter.css';


let scroll = Scroll.animateScroll;


export class AlfaFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapse: false
        };

        this.onEntered = this.onEntered.bind(this);
		this.toggle = this.toggle.bind(this);
    }

	toggle() {
		this.setState(state => ({ collapse: !state.collapse }));
	}

    onEntered() {
		scroll.scrollToBottom({ 'isDynamic': true, 'delay': 20, 'duration':40});
    }

	render() {


        return ( 
            <div className=" footer alfafootercontainer">
	            <div className="alfafooter">
		            <Button color="link" onClick={this.toggle}>
			            About
			            <div className="arrowbox"><ToggleAngle isExpanded={this.state.collapse} isVisible={true}/></div>
		            </Button>
	            </div>
	            <Collapse isOpen={this.state.collapse} onEntered={this.onEntered} appear={true} timeout={{ appear: 5, enter: 150, exit: 500 }}>
		            <div className="arrow-down" ></div>
                    <div className="contactInfo" ref={this.myRef} >
                        <h1>CALEIDO at your service</h1>
                        <p>CALEIDO, Classified Alfa Laval Electronic Information & Documents, is a library service that provides you with access to the right publications to support you in your sales activities.</p>
                        <p><img src={require("./caleido.png")}  alt="Caleido"/><span className="al-info">The CALEIDO library service is brought to you by Alfa Laval, it provides a reflection of content from our Digital Asset Library. The service exists in multiple forms allowing distribution of unique collections to its users</span></p>
                    </div>
	            </Collapse>

            </div>
	        );
	}

}