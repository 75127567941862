import './AssortmentTable.css';
import React, { Component } from 'react';
import { Row, Col, Spinner, InputGroup, Alert, Input, FormGroup, Label } from 'reactstrap';
import { ErrorModal } from './ErrorModal';
import { AssortmentRow } from './AssortmentRow';
import { getAssortment } from '../../api/AssortmentApi';
import { getAssetBlob } from '../../api/AssetApi';
import { filterRows } from '../AssortmentRowsBuilder'
import { appInsights } from '../../appInsights';
import { AssortmentTypeAhead } from './AssortmentTypeAhead'
import { DocumentTypeButtons } from '../DocumentTypeButtons';
import { APIContext } from '../../api/api';

import { AlfaFooter } from '../AlfaFooter';

export class AssortmentTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            originalRows: [],
            originalCurrentRows: [],
            documentFilter: [],
            productFilter: [],
            documentTypes: [],
            priorToFreeTextRows: [],
            freeTextFilter: "",
            showLegacyRange: false,
            downloading: false,
            hasLegacyRange: false,
            error: undefined
        };

        this.handleRowClick = this.handleRowClick.bind(this);

        this.closeErrorModal = this.closeErrorModal.bind(this);

        this.handleDocumentTypeFilter = this.handleDocumentTypeFilter.bind(this);
        this.handleProductFilter = this.handleProductFilter.bind(this);
        this.handleFreeTextEvent = this.handleFreeTextEvent.bind(this);

        this.loadingDoneEvent = this.loadingDoneEvent.bind(this);
        this.typeahead = React.createRef();
        this.document = React.createRef();
    }
     

    componentDidMount() {
        this.loadAssortment();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.Assortment !== prevProps.Assortment) {
            this.setState({
                rows: [],
                originalRows: [],
                originalCurrentRows: [],
                showLegacyRange: false
        });
           
            this.loadAssortment();
        }
    }

    async loadAssortment() {
        const usePIMData = this.props.UsePIMData;
        const assortmentRole = this.props.Assortment;
        this.loadingDoneEvent("Loading...");
        let t = await this.context.getToken();

        getAssortment(usePIMData, t, assortmentRole).then(data => {
            if (!data.ok) {
                this.setState({
                    error: { header: "Unexpected assortment problem", body: data.data }
                });
                return;
            }
            const originalRows = JSON.parse(JSON.stringify(data.data));
            const originalCurrentRows = JSON.parse(JSON.stringify(originalRows.filter((x) => this.filterLegacyRows(x, originalRows))));
            const priorToFreeTextRows = JSON.parse(JSON.stringify(data.data));
            const rows = this.state.showLegacyRange ? originalRows : originalCurrentRows;
            const documentTypes = [...new Set(rows.filter(r => r.node.type === "asset").map(x => x.item.type))];
            const hasLegacyRange = originalRows.length !== originalCurrentRows.length;
            this.setState({
                rows: rows,
                originalRows: originalRows,
                originalCurrentRows: originalCurrentRows,
                priorToFreeTextRows: priorToFreeTextRows,
                documentTypes: documentTypes,
                documentFilter: documentTypes.map(x => x.toUpperCase()),
                collapse: false,
                hasLegacyRange: hasLegacyRange
            });
            this.loadingDoneEvent(data.name);
        });
    }

    loadingDoneEvent(e) {
        if (this.props.onLoadingDone) {
            this.props.onLoadingDone(e);
        }
    }

    filterLegacyRows(row, originalRows) {
        if (row.item.lifeCycleStatus === "Obsolete") {
            return false;
        }
        if (row.node.type === "asset") {
            let parent = originalRows.find(x => x.item.id === row.item.parent);
            if (parent.item.lifeCycleStatus === "Obsolete") {
                return false;
            }
        }
    
        return true;
    }

    handleChild(node, copiedRows, parentIsExpanded, parentIsVisible) {
        let index = copiedRows.findIndex(r => { return r.item.id === node.item.id });
        if (node.item.url && !parentIsExpanded) {
            node.node.isVisible = false;
        }
        else {
            node.node.isVisible = parentIsExpanded && parentIsVisible;
        }
        copiedRows[index] = node;
        this.handleChildren(node, copiedRows, node.node.isExpanded, node.node.isVisible);
    }

    handleChildren(parent, copiedRows, isExpanded, isVisible) {
        let children = this.state.rows.filter(r => { return r.item.parent === parent.item.id });
        children.forEach(function (child) {
            this.handleChild(child, copiedRows, isExpanded, isVisible);
        }, this);
    }

    logRowClick(row) {
        if (row.node.type === "asset") {
            appInsights.trackEvent("Asset", { name: row.item.name, fragment: row.item.url });
            return;
        }
        appInsights.trackEvent("Node", { name: row.item.name });
    }

    handleRowClick(id, isExpanded, languageId) {
        let row = this.state.rows.find(r => { return r.item.id === id });
        this.logRowClick(row);
        if (row.item.url) {
            this.handleAssetRowClick(row, languageId);
            return;
        }
        let rows = this.state.rows;

        row.node.isExpanded = isExpanded;
        this.handleChildren(row, rows, isExpanded, row.node.isVisible);

        this.setState({
            rows: rows
        });
    }

    closeErrorModal() {
        this.setState({
            error: { header: "", body: "" }
        });
    }

    async handleAssetRowClick(row, languageId) {
        this.setState({
            downloading: true
        });
        let language = row.item.language; 
        let url;
        if (languageId) {
            const lang = row.item.otherLanguages.find(a => a.assetId === languageId);
            language = lang.language;
            url = lang.url;
        } else {
            url = row.item.url;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'file_download',
            'file_product_line': row.item.productLine,
            'file_lang': language,
            'file_name': row.item.name,
            'file_extension': row.item.fileExtension,
            'file_category': row.item.type,
            'file_context': 'Product Category'
        });
        let t =  await this.context.getToken();

        getAssetBlob(url, t)
            .then(response => {
                if (!response.ok) {
                    this.setState({
                        error: { header: "Unexpected download problem", body: response.data }
                    });
                }
                // IE - EDGE hack
                else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    let fileName = row.item.url.split("/").pop();
                    window.navigator.msSaveOrOpenBlob(response.data, fileName);
                } else {
                    let url = URL.createObjectURL(response.data);
                    window.open(url, "_blank");
                }
                this.setState({
                    downloading: false
                });
            });
       
    }

    handleFreeTextEvent(event) {
        if (!event || !event.target) {
            return;
        }
        let rows = this.state.showLegacyRange ? JSON.parse(JSON.stringify(this.state.originalRows)) : JSON.parse(JSON.stringify(this.state.originalCurrentRows));
        const freeText = event.target.value;
        rows = filterRows(this.state.documentFilter, this.state.productFilter, freeText, rows);
        this.setState({
	          rows: rows,
	          freeTextFilter: freeText
	    });

    }

    handleDocumentTypeFilter(filter) {
        let rows = this.state.showLegacyRange ? JSON.parse(JSON.stringify(this.state.originalRows)) : JSON.parse(JSON.stringify(this.state.originalCurrentRows));
        let documentFilter = filter.map(x => x.label.toUpperCase());
        if (documentFilter.length === 0) {
            documentFilter = this.state.documentTypes.map(x => x.toUpperCase());
        }

        rows = filterRows(documentFilter, this.state.productFilter, this.state.freeTextFilter, rows);
        this.setState({
	        rows: rows,
	        documentFilter: documentFilter,
	        priorToFreeTextRows: rows
        });
    }

    handleProductFilter(filter) {
        let rows = this.state.showLegacyRange ? JSON.parse(JSON.stringify(this.state.originalRows)) : JSON.parse(JSON.stringify(this.state.originalCurrentRows)) ;
        const productFilter = filter.map(x => x.label.toUpperCase());
        rows = filterRows(this.state.documentFilter, productFilter, this.state.freeTextFilter, rows);
        this.setState({
		        rows: rows,
		        productFilter: productFilter,
		        priorToFreeTextRows: rows

	        }
        );
    }

    handleShowLegacyRange() {
        let showLegacyRange = !this.state.showLegacyRange;
        let rows = showLegacyRange ? this.state.originalRows : this.state.originalCurrentRows;
        rows = filterRows([], [], [], rows);

        rows.forEach(function (row) {
            row.node.isExpanded = false;
            if (row.node.level > 1) {
                row.node.isVisible = false;
            }
        }, this);


        this.setState({
            rows:rows,
            showLegacyRange: showLegacyRange,
            documentFilter: [],
            productFilter: [],
            freeTextFilter: ""
            
        }, () => this.document.current.clear());
        this.typeahead.current.clear();
    }

    renderRow(row, nextRowLevel) {
        return (
            <AssortmentRow key={row.item.id} row={row} onClick={this.handleRowClick} options={this.state.options} nextRowLevel={nextRowLevel} searchFilter={this.state.freeTextFilter ? true : false} />
        );
    }

    renderRows(rows) {
        return (
            rows.map((row) => {
                if (row.node.isFiltered) {
                    const rowIndex = rows.indexOf(row);
                    if (rowIndex === rows.length - 1) {
                        return this.renderRow(row, undefined);
                    }
                    const nextRow = rows[rowIndex + 1];
                    const nextRowLevel = nextRow.node.level;
                    return this.renderRow(row, nextRowLevel);
                }
                return null;
            })
        );
    }

    renderFilter() {
        if (this.state.originalRows < 1) { 
            return (
                <Row><Col md={{ size: 'auto', offset: 6 }}><Spinner color="primary" /></Col></Row>
            );
        }
        return (
            <div>
                {this.state.hasLegacyRange ?
                    <FormGroup switch className="form-check-reverse">
                        <Label check>Show legacy products <i className="legacy-icon icon-support-tools"></i></Label>
                        <Input type="switch" role="switch" checked={this.state.showLegacyRange} onChange={() => {
                            this.handleShowLegacyRange()
                        }} />
                    </FormGroup>
                    : <div></div>
                }
                <InputGroup>
                    <DocumentTypeButtons ref={this.document} documentTypes={this.state.documentTypes} onFilterChange={this.handleDocumentTypeFilter} />
                </InputGroup>
                <br />
                <InputGroup>
                    <AssortmentTypeAhead ref={this.typeahead} data={this.state.showLegacyRange ? this.state.originalRows : this.state.originalCurrentRows} change={this.handleProductFilter} />
                </InputGroup>
                <br />
                <InputGroup>
                    <input type="text" className="form-control" onChange={this.handleFreeTextEvent} placeholder="Search documents" value={this.state.freeTextFilter} />
                </InputGroup>
            </div>
        );
    }

    render() {
        const filter = this.state.originalRows < 1
            ? <Row><Col md={{ size: 'auto', offset: 6 }}><Spinner color="primary" /></Col></Row>
            : <div>
               
                {this.state.hasLegacyRange ?
                    <FormGroup switch className="form-check-reverse">
                        <Label check>Show legacy products <i className="legacy-icon icon-support-tools"></i></Label>
                        <Input type="switch" role="switch" checked={this.state.showLegacyRange} onChange={() => {
                            this.handleShowLegacyRange()
                        }} />
                    </FormGroup>
                    : <div></div>
                }
                <InputGroup>
                    <DocumentTypeButtons ref={this.document}  documentTypes={this.state.documentTypes} onFilterChange={this.handleDocumentTypeFilter} />
                </InputGroup>
                <br />
                <InputGroup>
                    <AssortmentTypeAhead ref={this.typeahead} data={this.state.showLegacyRange ? this.state.originalRows : this.state.originalCurrentRows} change={this.handleProductFilter} />
                </InputGroup>
                <br />
                <InputGroup>
                    <input type="text" className="form-control" onChange={this.handleFreeTextEvent} placeholder="Search documents" value={this.state.freeTextFilter} />
                </InputGroup>

            </div>;


        return (
            <div>
                <Alert color="primary" className="fixed-top" isOpen={this.state.downloading}>Downloading...  <Spinner color="primary" /></Alert>
                
                <ErrorModal error={this.state.error} onClose={this.closeErrorModal} />
                {filter}

                <div className="table-responsive-sm" style={{ height: "100%" }}>
                    <table className="table catalog-table">
                        <thead>
                            <tr>
                                <th className="col-1" />
                                <th className="col-2" />
                                <th className="col-3" />
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows(this.state.rows)}
                        </tbody>
                    </table>
                </div>
                <AlfaFooter />

            </div>
        );
    }
}

AssortmentTable.contextType = APIContext;

