import { Component } from 'react';
import { Layout } from './Layout';
import Catalog from './Catalog';

class ExtranetFrame extends Component {
    
    constructor(props) {
        super(props);
        this.setAssortmentName = this.setAssortmentName.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.state = { AssortmentName: 'Loading...', activeRole: null};
     
    }

    setAssortmentName(e) {
         this.setState({ AssortmentName: e });
    }

    onRoleChange(e) {
        this.setState({ activeRole: e });

    }

    render() {
        return (
            <Layout AssortmentName={this.state.AssortmentName} onRoleChange={this.onRoleChange}>
                <Catalog onLoadingDone={this.setAssortmentName} Assortment={this.state.activeRole}/>
            </Layout>
        );
    }

}

export default ExtranetFrame;