//import { getToken } from '../Auth';
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function getAssetBlob(url, token) {
    return fetch(encodeURI(apiUrl + "/api/files?url=" + url),
                {
                    headers: {
                        'v': '1.0',
                        'accept': 'application/pdf image/*',
                        'Authorization': 'Bearer ' + token,
                        'Cache-Control': 'no-cache, no-store, must-revalidate'
                    }
                })
        
        .then(response => {
            if (!response.ok) {
                return response.json();
            }
            return response.blob();
        })
        .then(data => {
            if (data instanceof Blob) {
                return { ok: true, data: data };
            }
            return { ok: false, data: data.message };
        })
        .catch(error => {
            return { ok: false, data: "Unhandled error:" + error };
        });
}

export function getAssetThumbnail(url, token) {
    return fetch(encodeURI(apiUrl + "/api/files?url=" + url),
                {
                    headers: {
                        'v': '1.0',
                        'accept': 'application/pdf image/*',
                        'Authorization': 'Bearer ' + token,
                        'Cache-Control': 'public, max-age=3600'
                    }
                
        })
        .then(response => {
            if (!response.ok) {
                return { ok: false, data: undefined };
            }
            return response.blob();
        })
        .then(blob => {
            return { ok: true, data: blob };
        })
        .catch(err => {
            return { ok: false, data: undefined };
        });
}