
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function getCacheInfo(token) {
    return fetch(encodeURI(apiUrl + "/api/admin/cache/pim/info" ),
            {
                headers: {
                    'accept': '*/*',
                    'Authorization': 'Bearer ' + token
                    
                }
            })

        .then(response => {
            
            return response.json();
        })
        .catch(error => {
            return { ok: false, data: "Unhandled error:" + error };
        });
}

export function clearCache(token) {
    return fetch(encodeURI(apiUrl + "/api/admin/cache/pim/clear"),
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
        })
        .then(response => {
            return  { ok: true, data: "response" };
        })
        .catch(error => {
            return { ok: false, data: "Unhandled error:" + error };
        });
}