
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: "https://login.microsoftonline.com/ed5d5f47-52dd-48af-90ca-f7bd83624eb9",
        redirectUrl: process.env.REACT_APP_REDIRECT_URL
    },
    cache: "sessionStorage",
    storeAuthStateInCookie: false,
};

export const loginRequest = {
    scopes: [process.env.REACT_APP_SCOPE]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};