import React, { Component } from 'react';

export class ToggleAngle extends Component {
    render() {
        if (!this.props.isVisible) {
            return null;
        }
        const isExpanded = this.props.isExpanded;
        let classNames = "icon-chevron-";
        if (isExpanded) {
            classNames += "down level-" + this.props.level;
        } else {
            classNames += "right level-" + this.props.level;
        }
        return <i className={classNames}></i>;
    }
}