import React, { Component } from 'react';
import { MsalContext } from "@azure/msal-react";
import { APIContext } from '../api/api';
import { getCacheInfo, clearCache } from '../api/AdminApi';
import { Button } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cacheInfo: []
        }
        this.loadingDoneEvent = this.loadingDoneEvent.bind(this);
        dayjs.extend(utc);
    }

    loadingDoneEvent(e) {
        if (this.props.onLoadingDone) {
            this.props.onLoadingDone(e);
        }
    }

    componentDidMount() {
        this.loadCacheInfo();
    }

    async clearCache() {
        let t = await this.context.getToken();
        clearCache(t);
        this.loadCacheInfo();
    }

    async loadCacheInfo() {

        let t = await this.context.getToken();

        getCacheInfo(t).then(response => {

            const infos = response.map((info) =>
                <li className="list-group-item d-flex justify-content-between align-items-start" key={info.id}>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{info.name}</div>
                        Expires:  {dayjs.utc(info.expires).local().format('YYYY-MM-DD HH:mm')} <br />
                    </div>
                </li>
            );

            this.setState({
                cacheInfo: infos
            });
            this.loadingDoneEvent("Admin pages");
        });
    }

    render() {

        return (
            <div>
                <h2>Caleido cache</h2>
                {this.state.cacheInfo.length > 0 ?
                    <div>
                        <p>These items are currently cached in Caleido</p>
                        <div className="m-2">
                            <Button color="info" className="ml-auto" onClick={() => this.loadCacheInfo()}>Refresh</Button>
                            <Button color="danger" className="ml-auto" onClick={() => this.clearCache()}>Clear the cache</Button>
                        </div>
                        <ol className="list-group" style={{ maxWidth: '400px' }}  >
                            {this.state.cacheInfo}
                        </ol>
                    </div>
                    :
                    <div className="m-2">
                        <Button color="info" className="ml-auto" onClick={() => this.loadCacheInfo()}>Refresh</Button>

                        <p>No cached items</p></div>

                }
            </div>
        );
    }
}

Admin.contextType = APIContext;