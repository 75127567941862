import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ExtranetFrame from './components/ExtranetFrame';
import Catalog from './components/Catalog';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/ALicons.woff';
import './styles/al-custom-btsp.scss';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';

const App = () => {
    useMsalAuthentication(InteractionType.Redirect);

    function PageViewEvent() {
        const { accounts } = useMsal();
        const isFirstRender = useRef(true);
        const isAuthenticated = useIsAuthenticated();

        useEffect(() => {
            if (isFirstRender.current && isAuthenticated) {
                const id = accounts[0].idTokenClaims.oid;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'page_view',
                    'page_category': ['Caleido'],
                    'user_id': id
                });
                isFirstRender.current = false;
            }
        }, [isAuthenticated, accounts]);
    }
    PageViewEvent();

    return (
        <>
            <AuthenticatedTemplate>
                <Router>
                    <Routes>
                        <Route path="/" element={<ExtranetFrame />} />
                        <Route path="/clean" element={<Catalog />} />
                    </Routes>
                </Router>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>You are not signed in! Please sign in.</p>
            </UnauthenticatedTemplate>
        </>
    );
}

export default App;