import React, { useState, useContext } from 'react';
import { loginRequest } from '../authConfig';

const pick = attr => obj => obj[attr];

class API {
    constructor({ msalInstance }) {
        this.msalInstance = msalInstance;
        this.account = this.msalInstance.getAllAccounts()[0];
        this.token = null;
    }

    renewToken = async () => {
        if (!this.account) {
            this.account = this.msalInstance.getAllAccounts()[0];
        }
        const silentRequest = {
            ...loginRequest,
            account: this.account,
        };

        this.token = await this.msalInstance
            .acquireTokenSilent(silentRequest)
            .then(pick('accessToken'))
            .catch(error => {
                if (error.name === 'InteractionRequiredAuthError') {
                    // fallback to interaction when silent call fails
                    return this.msalInstance.acquireTokenRedirect(silentRequest);
                }
            });
    };

    getToken = async () => {
        await this.renewToken();

       return this.token;
    };
}

const APIContext = React.createContext(null);

const APIProvider = props => {
    const [api] = useState(props.api);

    return (
        <APIContext.Provider value={{ ...api }}>
            {props.children}
        </APIContext.Provider>
    );
};

const useAPI = () => useContext(APIContext);

export { API, APIProvider, APIContext };
export default useAPI;