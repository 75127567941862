import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { AlfaHeader } from './AlfaHeader';

export class Layout extends Component {
    constructor(props) {
        super(props);

        this.roleChange = this.roleChange.bind(this);
    }

    static displayName = Layout.name;

    roleChange(e) {
        if (this.props.onRoleChange) {
            this.props.onRoleChange(e);
        }
    }

    render() {
        return (
            <div  >
                {/*<NavMenu name={this.props.AssortmentName} />*/}
                <AlfaHeader name={this.props.AssortmentName} onRoleChange={this.roleChange} />
                <Container>
                    {this.props.children}
                </Container>

            </div>
        );
    }
}
