import { Component } from 'react';
import { AssortmentTable } from './table/AssortmentTable';
import Admin from './Admin';


class Catalog extends Component {
    
    constructor(props) {
        super(props);
        this.onLoadingDone = this.onLoadingDone.bind(this);
        this.state = { AssortmentName: 'Loading...' };
    }

    onLoadingDone(e) {
        if (this.props.onLoadingDone) {
            this.props.onLoadingDone(e);
        }
    }

    render() {
        return (
            <>
                {this.props.Assortment === 'Admin' ?
                    <Admin onLoadingDone={this.onLoadingDone} />
                :
                    <AssortmentTable UsePIMData={this.props.UsePIMData} onLoadingDone={this.onLoadingDone} Assortment={this.props.Assortment} />
                }
            </>
        );
    }

}

export default Catalog;