import React, { useState } from 'react';
import './AlfaHeader.css';
import AlfaLogo from './logo-alfalaval.svg';
import { getLanguageForIsoCode } from './table/Languages';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";


export const AlfaHeader = (props) => {
    const language = getLanguageForIsoCode(navigator.language || navigator.userLanguage);
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    const roles = accounts[0] && accounts[0].idTokenClaims.roles;
    const [activeRole, setActiveRole] = useState(roles.includes("SalesGPHEAlfaLaval") ? "SalesGPHEAlfaLaval" : roles[0]);

    const userRoles = [];

    function changeRole(role) {
        setActiveRole(role);
        if (props.onRoleChange) {
            props.onRoleChange(role);
        }
    }

    roles.forEach((role) => {
        userRoles.push(<DropdownItem key={role} onClick={() => changeRole(role)}>{role}</DropdownItem>);
    });

    return (
        <header>
            <div className="menu">
                <div className="item toprow"></div>
                <div className="item pathrow padding-left"></div>
                <div className="item pathrow">
                    <div className="path-white"></div>
                </div>
                <div className="item pathrow path-grey">
                    <div className="path">
                        <span>{language}</span>
                        {isAuthenticated && <span>{name}</span>}
                        {roles.length > 1 && 
                            <UncontrolledDropdown size="sm" tag='span'  >
                                <DropdownToggle caret className='roleDropDown'>
                                    {activeRole}
                            </DropdownToggle>
                                <DropdownMenu end={true} className='roleDropDownItems'>
                                {userRoles}
                            </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                    </div>
                </div>
                <div className="item pathrow padding-right"></div>
                <div className="item bootomrow">
                    <img src={AlfaLogo} alt="Logo" style={{ height: '100%' }} />
                    <span className="heading">CALEIDO</span>
                    <span className="menutext font-weight-bold">{props.name} </span>
                    <span className="link"> </span>
                </div>
                <div className="divider"></div>
            </div>

            
        </header>

    );
} 