import React from 'react';
import ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { APIProvider, API } from './api/api';

import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

const api  = new API({ msalInstance });

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <APIProvider api={api}>
                <App />
            </APIProvider>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
