import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class ErrorModal extends Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
    }

    hasError() {
        return this.props.error !== undefined && this.props.error.body !== undefined && this.props.error.body.length > 0;
    }

    close() {
        this.props.onClose();
    }

    static defaultProps = {
        error: {
            header: '',
            body: ''
        }
    }

    render() {
        var hasErrors = this.hasError();
        if (!hasErrors) {
            return null;
        }

        return (
            <div>
                <Modal isOpen={hasErrors} toggle={this.close}>
                    <ModalHeader toggle={this.close}>{this.props.error.header}</ModalHeader>
                    <ModalBody>{this.props.error.body}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.close}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}